import React from 'react';
import { Logo } from '../CommonIcons/commonIcons';
import { Box, HStack, IconButton, Stack } from '@chakra-ui/react';
import { IconBook2, IconChartPie, IconHome, IconMailForward, IconMessage, IconSchema, IconSettings, IconTable } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconList } from '@tabler/icons-react';
import { useAppContext } from '../../appContext';


const Navigation = () => {
    const { currentUser } = useAppContext()
    const iconProps = {

        size: "25px"
    }
    return (
        <Stack width="50px" m="8px">

            {/* Your component JSX goes here */}
            <Stack p="4px" align="center" border="1px solid gray" borderRadius="8px" color="gray">
                {/* <NaviButton text="Chat" Icon={IconMessage} path="/chat" /> */}
                <NaviButton text="Home" Icon={IconHome} path="/home" />
                <NaviButton text="Lists" Icon={IconTable
                } path="/lists" />
                <NaviButton text="Inbox" Icon={IconMailForward} path="/inbox" />
                {/* <NaviButton text="Workflows" Icon={IconSchema} path="/workflows" /> */}
                <NaviButton text="Analytics" Icon={IconChartPie} path="/analytics" />
                {/* {currentUser?.tenant_id == "1" && <NaviButton text="Recipes" Icon={IconBook2} path="/recipes" />} */}
                <NaviButton text="Settings" Icon={IconSettings} path="/settings" />

            </Stack>
        </Stack>
    );
};

const NaviButton = ({ text, Icon, path }: {
    text: string,
    Icon: any,
    path: string

}) => {
    const currentLocation = useLocation();
    const isActive = currentLocation.pathname.startsWith(path);
    const navigate = useNavigate()
    return (
        <Stack >
            <IconButton
                onClick={() => {
                    navigate(path)
                }}
                variant={isActive ? "solid" : "outline"}
                icon={<Icon size="24px" color={isActive ? "red" : "gray"} />} aria-label={text} />
            {/* Your component JSX goes here */}
            <Stack>


            </Stack>
        </Stack>
    );
}

export default Navigation;