import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList, AutoCompleteTag } from "@choc-ui/chakra-autocomplete";
import { useEffect, useMemo, useState } from "react";
import { useApiEndpoint, useApiFetch } from "../../utils/useApiHook";
import { Spinner, Text } from "@chakra-ui/react";

const CampaignsSelect = ({ campaignId, onChange }: { campaignId: string, onChange: (value: { id: string, name: string }) => void }) => {

    const [query, setQuery] = useState("")
    const [selected, setSelected] = useState<{ id: string, name: string }>()
    const campaignSearchEndpoint = useApiFetch<{ id: string, name: string }[]>(`/analytics/campaigns`, { queryArgs: { "q": query } })
    const getCampaignEndpoint = useApiEndpoint<{ id: string, name: string }>("GET", `/analytics/campaigns/{id}?include_stats=false`, false, false)

    useEffect(() => {
        if (!(selected && selected.id === campaignId)) {
            if (campaignId) {
                if (campaignSearchEndpoint.data?.find(v => v.id === campaignId)) {
                    setSelected(campaignSearchEndpoint.data.find(v => v.id === campaignId))
                }
                else {

                    getCampaignEndpoint.execute({ id: campaignId }).then((val) => {
                        setSelected(val)
                    })
                }
            }
            else {
                setSelected(undefined)
            }
        }
    }, [campaignId])

    return <AutoComplete openOnFocus
        value={query}
        disableFilter
        isLoading={campaignSearchEndpoint.isLoading}
        onSelectOption={(val) => {
            onChange(val.item.originalValue as any)
            setQuery("")

        }}>



        {selected ? <AutoCompleteTag

            label={selected.name}
            onRemove={() => {
                onChange(undefined)
                setSelected(undefined)
            }}
        /> : <AutoCompleteInput size="sm" placeholder="Select campaign"
            value={query} onChange={(e) => setQuery(e.target.value)}

        />}

        {/* {campaignSearchEndpoint.isLoading && <Spinner size="xs" />} */}
        <AutoCompleteList >

            {campaignSearchEndpoint.data?.map((v, cid) => (
                <AutoCompleteItem
                    fontSize="sm"
                    key={`option-${cid}`}
                    value={v}

                    _selected={{ bg: "whiteAlpha.50" }}
                    _focus={{ bg: "whiteAlpha.100" }}
                >
                    {v.name}
                </AutoCompleteItem>
            ))}
        </AutoCompleteList>
    </AutoComplete>
}

export default CampaignsSelect