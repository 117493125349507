
import { Box, Button, Checkbox, Flex, HStack, IconButton, Input, Spinner, Stack, Tag, TagLabel, Text, Tooltip, useToast } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import GenericList from '../GenericList/genericList';
import { AutoUI, ChatContainer, ChatMessage, GeniouslyThemeProvider, AutoDatePicker, AutosizeTextArea } from 'geniously-chat-ui';
import { useApiEndpoint, useApiFetch } from '../../utils/useApiHook';
import { useNavigate, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { EditModal } from '../ModalDialogs/editModal';
import { IconArchive, IconBrandGmail, IconBrandLinkedin, IconChevronDown, IconChevronRight, IconId, IconLayoutSidebarRightCollapse, IconLayoutSidebarRightExpandFilled, IconMail, IconMailExclamation, IconMailFilled, IconPlus, IconSend } from '@tabler/icons-react';
import { ConfirmModal } from '../ModalDialogs/confirmModal';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
    AutoCompleteTag,
} from "@choc-ui/chakra-autocomplete";
import { IconChevronLeft } from '@tabler/icons-react';


const ContactCard = ({ contact: item, onToggleDetail: onToggleDetail, detailsOpen, children, ...rest }: { contact: any, detailsOpen?: boolean, onToggleDetail?: () => any, [key: string]: any }) => {

    return <Stack {...rest} border="1px solid" borderRadius={6} p="4px 8px" spacing={0} fontWeight={500}>
        <HStack justify="space-between">
            <Text fontSize="sm" fontWeight={700}>{item.full_name}</Text>

            <HStack>
                {item.email &&
                    <Tooltip label={<>{item.email}<br />Click to copy</>}>
                        <IconButton size="xs" aria-label="LinkedIn" p="0px" icon={<IconMail color="#0f0f0f" size="20px" />} onClick={() =>
                            navigator.clipboard.writeText(item.email)
                        } />
                    </Tooltip>
                }
                {item.linkedin_url && <Tooltip label={<><Text>{item.linkedin_url}</Text><br />Click to view profile</>}>
                    <IconButton size="xs" aria-label="LinkedIn" p="0px" icon={<IconBrandLinkedin color="blue" size="20px" />} onClick={() => window.open(item.linkedin_url)} /></Tooltip>}
                {onToggleDetail &&
                    <Tooltip label={detailsOpen ? "Hide details" : "Show details"}>
                        <IconButton size="xs" aria-label="Details" p="0px" icon={!detailsOpen ? <IconLayoutSidebarRightExpandFilled /> : <IconLayoutSidebarRightCollapse size="20px" />} onClick={onToggleDetail} />
                    </Tooltip>
                }
            </HStack>
        </HStack>
        <HStack>
        <Tooltip label={<Moment>{item.last_activity}</Moment>}><Text as={"span"} fontSize="xs">{<Moment fromNow>{item.last_activity}</Moment>}</Text></Tooltip>
            {item.waiting_for_activity && <IconMailExclamation size="17px" />}
        </HStack>
        <Text fontSize="xs">{item.headline}</Text>

        {item.company && !(item.headline && item.headline.includes(item.position) && item.headline.includes(item.company)) && <Text fontSize="xs">{item.position} at {item.company}</Text>}
        {children}
    </Stack>
}

export default ContactCard